// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { number, object, bool, func, array, string, oneOfType, } from 'prop-types'
import { isEqual, isEmpty, } from 'lodash'
import { Formik, Field, } from 'formik'
import { Col, FormGroup, Label, Row, Popover, PopoverBody, } from 'reactstrap'

// Components
import Effect from '../Effect'
import ValidatingField from '../FormControls/ValidatingField'

// Actions
import BurnPermitLocationActions from '../../redux/BurnPermitLocationRedux'
import MapActions from '../../redux/MapRedux'
import GeoCoordinateTypes from '../../redux/GeoCoordinateRedux'

// Models
import BurnPermitLocation from '../../models/BurnPermitLocation'

// Selectors
import { mapStateSelector, } from '../../selectors/mapSelectors'
import { geoStateSelector, } from '../../selectors/geoSelectors'
import { networkStateSelector, permitLocationByIdSelector, } from '../../selectors/selectors'

// Map
import { TRS, FIRE_DISTRICTS, COUNTIES, REGIONS, } from '../../config/map/featureLayers'

// Utilities
import retry from '../../utilities/retry'

// These are layers that should be shown upon click of the GeoLocation button so that
// their values can be derived from the lat/long and populate their respective forms
const LAYERS_TO_SHOW = [ TRS.id, FIRE_DISTRICTS.id, COUNTIES.id, REGIONS.id, ]

/**
 * @class GeoCoordinateForm
 * @description
 * Elements have these names:
 *    'Latitude',
 *    'Longitude',
 */
class GeoCoordinateForm extends React.Component {

  constructor (props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
    this.validationSchema = BurnPermitLocation.getValidationSchema({ latLong: true, })
    this.hasValues = this.hasValues.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  static propTypes = {
    UpdateLatLong        : func,
    SetLatLong           : func,
    ShowLayer            : func,
    MapLatitude          : oneOfType([ number, string, ]),
    MapLongitude         : oneOfType([ number, string, ]),
    permitLocation       : object,
    hiddenLayerIds       : array,
    BurnPermitLocationId : number,
    MapGetLatLong        : func,
    GetLatLongFromMap    : bool,
    onChange             : func,
    readOnly             : bool,
    setMapLatLong        : func,
    Geocode              : func,
    online               : bool,
  }

  static defaultProps = { readOnly: false, }

  state = {
    latLongPopoverMessage : '',
    latLongPopoverOpen    : false,
    
  }

  componentDidMount () {
    const { permitLocation, } = this.props
    if (!isEmpty(permitLocation)) {
      const { Latitude, Longitude, } = permitLocation
      this.props.SetLatLong(Latitude, Longitude)
    }
  }

  componentWillUnmount () {
    this.props.MapGetLatLong(false, false)
  }

  componentDidUpdate (prevProps) {
    const {
      permitLocation,
      GetLatLongFromMap,
      MapLatitude,
      MapLongitude,
      SetLatLong,
    } = this.props

    // This handles if the lat/long was updated on the server and then the local state
    // is updated after a fetch returns the new data
    if (!isEmpty(prevProps.permitLocation) && !isEmpty(permitLocation) && !isEqual(prevProps.permitLocation, permitLocation)) {
      const latitude = permitLocation.Latitude
      const longitude = permitLocation.Longitude
      this.props.setMapLatLong({
        mapPoint: {
          latitude,
          longitude,
        },
      })
      SetLatLong(latitude, longitude)
      this.validateForm()
      return
    }

    // If we're getting the values from the map, check them against what the form is tracking
    if (GetLatLongFromMap && !!this.formik) {
      const { Latitude: formLat, Longitude: formLong, } = this.formik.values
      const LatLong = { Latitude: MapLatitude, Longitude: MapLongitude, }
      const latLongHasNewValues = Object.values(LatLong).filter(l => l).length && !isEqual({ Latitude: formLat, Longitude: formLong, }, LatLong)
      // If they're different, update the form
      if (latLongHasNewValues) {
        this.setState({ latLongPopoverMessage: 'Click the button when you have found the lat / long you desire', }, () => {
          this.formik.setValues(LatLong)
          this.formik.setTouched({ Latitude: true, Longitude: true, })
        })
      }
    }
  }

  async validateForm (force) {
    if (!this.formik) {
      return await retry(this.validateForm, force, 100)
    }
    this.formik.setFieldValue('Force', force || false)
    const formKeys = Object.keys(this.formik.values)
    for (let i = 0, len = formKeys.length; i < len; i++) {
      this.formik.setFieldTouched(formKeys[i])
    }
    return await this.formik.validateForm()
  }

  resetForm = () => {
    this.formik.resetForm()
  }

  submitForm = () => {
    this.setState({
      latLongPopoverOpen    : false,
      latLongPopoverMessage : '',
    })
    this.formik.submitForm()
    this.props.MapGetLatLong(false, false)
  }

  getLatLongFromMap = () => {
    const isOpen = !this.state.latLongPopoverOpen
    this.setState({
      latLongPopoverOpen    : isOpen,
      latLongPopoverMessage : 'Click the map to set the Lat / Long values',
    }, () => {
      let id
      for (let i = 0, len = LAYERS_TO_SHOW.length; i < len; i++) {
        id = LAYERS_TO_SHOW[i]
        if (this.props.hiddenLayerIds.includes(id)) {
          this.props.ShowLayer(id)
        }
      }
      this.props.MapGetLatLong(isOpen, isOpen)
    })
  }

  onChange = (changedEntries) => {
    if (!this.formik) {
      setTimeout(() => this.onChange(changedEntries), 100)
      return
    }
    const { Latitude, Longitude, } = this.formik.values
    if (!Latitude || !Longitude) {
      this.props.SetLatLong(null, null)
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(changedEntries)
    }
    if (Object.keys(this.formik.errors).length > 0) {
      // If there are any errors, make sure both inputs are touched
      // so that all validation messages will show immediately
      const formKeys = Object.keys(this.formik.values)
      for (let i = 0, len = formKeys.length; i < len; i++) {
        this.formik.setFieldTouched(formKeys[i])
      }
    }
  }

  submit = values => {
    const { BurnPermitLocationId, } = this.props

    if (BurnPermitLocationId) {
      const {
        Latitude,
        Longitude,
      } = values
      const latLongInfo = {
        Latitude,
        Longitude,
        BurnPermitLocationId,
      }
      this.props.UpdateLatLong(latLongInfo)
    }
  }

  hasValues () {
    if (!this.formik) {
      setTimeout(() => this.hasValues(), 100)
      return
    }
    const { Latitude, Longitude, } = this.formik.values

    // If the user enters a value in one or more inputs, but then clears them, `dirty` will return false
    return !!Latitude && !!Longitude
  }

  onBlur () {
    // If the form hasn't been touched or the user is using the map exit early
    if (!this.formik || !this.formik.touched || this.props.GetLatLongFromMap || !this.props.online) {
      return
    }
    
    // validate the form with force = true to see if we should geocode
    this.formik
      .validateForm({
        ...this.formik.values,
        Force: true,
      })
      .then((errors) => {
        if (!isEmpty(errors)) {
          return
        }
        const { Latitude, Longitude, } = this.formik.values
        if (!Latitude || !Longitude) {
          return
        }
        // call the geocode method
        this.props.Geocode(Latitude,Longitude)
        // make the map display the typed lat long
        this.props.setMapLatLong({ 
          mapPoint: {
            latitude  : Latitude,
            longitude : Longitude,
          },
        })
      })
      .catch(err => console.error('An error occurred validating the address form', err))
  }

  setFormikNode = node => this.formik = node

  render () {
    const { readOnly, } = this.props
    const { Latitude, Longitude, } = this.props.permitLocation
    const btnLabelClass = `btn btn-light ${this.props.GetLatLongFromMap ? 'active' : ''}`
    return (
      <Formik
        initialValues={{
          Latitude  : Latitude,
          Longitude : Longitude,
          Force     : false,
        }}
        validationSchema={this.validationSchema}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
        enableReinitialize={true}
      >
        {({ values, }) => (
          <>
            <Effect values={values} onChange={this.onChange} />
            <Row>
              <Col>
                <p>To auto-populate the Lat/Long, Legal Description, Region, County, and Fire District from the Map below, <b>you must first zoom in close to your burn location.</b></p>
                <p>Then, click the Globe button on the right, then click on the map to record the location of your burn location.</p>
                <p><small>You may have to click the map twice.</small></p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for={'txt-latitude'}>Latitude</Label>
                  <Field
                    name={'Latitude'}
                    id={'txt-latitude'}
                    type={'number'}
                    step={.0001}
                    placeholder={'47.620534'}
                    min={BurnPermitLocation.fields.Latitude.opts.min}
                    max={BurnPermitLocation.fields.Latitude.opts.max}
                    readOnly={readOnly}
                    component={ValidatingField}
                    onBlur={this.onBlur}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for={'txt-longitude'}>Longitude</Label>
                  <Field
                    name={'Longitude'}
                    id={'txt-longitude'}
                    type={'number'}
                    step={.0001}
                    placeholder={'-122.349306'}
                    min={BurnPermitLocation.fields.Longitude.opts.min}
                    max={BurnPermitLocation.fields.Longitude.opts.max}
                    readOnly={readOnly}
                    component={ValidatingField}
                    onBlur={this.onBlur}
                  />
                </FormGroup>
              </Col>
              {!readOnly &&            
              <Col md={'2'} className={'d-flex'}>
                <div id={'lat-long-btn'} className={'btn-group-toggle'} data-toggle={'buttons'} style={{ alignSelf: 'center', }}>
                  <label className={btnLabelClass} style={{ padding: '0.5em', }}>
                    <input type={'checkbox'} checked={this.props.GetLatLongFromMap} onChange={this.getLatLongFromMap} autoComplete={'off'} />
                    <span className={'fa fa-globe'} alt={'Get Lat/Long from Map'} title={'Get Lat/Long from Map'} style={{ fontSize: '1.5em', verticalAlign: 'middle', top: 0, }}></span>
                  </label>
                </div>
                <Popover placement={'right'} isOpen={this.state.latLongPopoverOpen} target={'lat-long-btn'}>
                  <PopoverBody>{this.state.latLongPopoverMessage}</PopoverBody>
                </Popover>
              </Col>
              }
            </Row>
          </>
        )}
      </Formik>
    )
  }
}

function mapStateToProps (state, props) {
  const { online, } = networkStateSelector(state)
  const { GetLatLongFromMap, LatLong, hiddenLayerIds, } = mapStateSelector(state)
  const { IsGeocoding, geocodedLocation, geocodingLocation, } = geoStateSelector(state)
  const permitLocation = permitLocationByIdSelector(state, props.BurnPermitLocationId)

  return {
    hiddenLayerIds,
    permitLocation,
    GetLatLongFromMap,
    MapLatitude  : LatLong.Latitude,
    MapLongitude : LatLong.Longitude,
    IsGeocoding,
    geocodedLocation,
    geocodingLocation,
    online,
  }
}

const mapDispatchToProps = {
  UpdateLatLong : BurnPermitLocationActions.updateBurnPermitLocationLatLong,
  MapGetLatLong : MapActions.getLatLongFromMap,
  SetLatLong    : MapActions.setLatLong,
  ShowLayer     : MapActions.showLayer,
  Geocode       : GeoCoordinateTypes.geocodeLocationSection,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(GeoCoordinateForm)
