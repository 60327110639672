import { createSelector, } from 'reselect'

const geoState = (state) => state.GeoCoordinate

export const geoStateSelector = createSelector(
  geoState,
  (state) => state
)

const geoLocationSelector = createSelector(
  geoStateSelector,
  ({ geocodedLocation, }) => geocodedLocation,
)

export const geoLocationFormSelector = createSelector(
  geoLocationSelector,
  (features) => {
    return Object.entries(features).reduce((acc, curr) => {
      const [ k, ] = curr
      const f = features[k]
      if (!f || !f.layerId) {
        return acc
      }
      acc.push(f)
      return acc
    }, [])
  }
)