// Libraries
import React, { useMemo, } from 'react'
import { render as ReactDOMRender, } from 'react-dom'
import { useNavigate, Link, } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardHeader, Button, } from 'reactstrap'
import { bool,  array, string, node, } from 'prop-types'
import { isEmpty, } from 'lodash'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import ESRIMap from '../ESRIMap'
import SimpleDataTable from '../SimpleTable'
import { DangerIcon, SuccessIcon, } from '../Icons'

// Selectors
import { burnRequestMapDataSelector, } from '../../selectors/burnRequestSelectors'

// Models
import BurnRequestSearch from '../../models/BurnRequestSearch'

// Utilities
import stopEvent from '../../utilities/stopEvent'

// Hooks
import { useGetSearchExportFilename, } from '../../hooks'

// Map Config
import { burnRequestAction, permitAction, } from '../../config/map/actions'

import BurnRequestListActions from '../../redux/BurnRequestListRedux'

const defaultLayerObj = {}
const mapColStyle = { height: '530px', }

export const BurnRequestTable = ({
  requests,
  aboveTableContent,
  belowTableContent,
  showMap,
  isAuthenticated,
  showCardHeader = false,
  showLandownerAgent = false,
  showPermitAction = false,
  layerConfig,
}) => {
  const { columns, reqIdColIdx, permNumColIdx, postBurnColIdx, } = React.useMemo(() =>  {
    const cols = [ ...BurnRequestSearch.getDataTablesColumns({ showLandownerAgent: isAuthenticated, }), ]
    return {
      columns        : cols,
      reqIdColIdx    : cols.findIndex(c => c.title === 'Burn Request ID'),
      permNumColIdx  : cols.findIndex(c => c.title === 'Permit Number'),
      postBurnColIdx : cols.findIndex(c => c.title === 'Post-Burn'),
    }
  }, [ isAuthenticated, ])
  const reduxDispatch = useDispatch()

  const mapClass = useMemo(() => {
    let classString = 'map-container rounded'
    if (showMap) {
      classString += ' my-1'
    }
    else {
      classString += ' d-none'
    }
    return classString
  }, [ showMap, ])
  const navigate = useNavigate()

  // Burn Request map layer selector
  const BurnRequestsMapLayer = useSelector((state) => {
    if (showMap) {
      const layerData = burnRequestMapDataSelector(state, showLandownerAgent)
      if (layerData) {
        const bindProps = { navigate, }
        if (showPermitAction) {
          layerData.actions = [ burnRequestAction.bind(bindProps)(), permitAction.bind(bindProps)('BurnRequests'), ]
        } else {
          layerData.actions = [ burnRequestAction.bind(bindProps)(), ]
        }
      }
      return layerData
    }
    return defaultLayerObj
  })

  const buildCellLink = React.useCallback((cell, href, text) => {
    return ReactDOMRender(
      <a href={href}
        className={'text-dark'}
        onClick={e =>{
          stopEvent(e)
          const pathname = e.target.href.replace(window.location.origin, '')
          navigate(pathname)
        }}>{text}</a>
      , cell
    )
  }, [ navigate, ])

  const getFileName = useGetSearchExportFilename('BurnRequestSearch')

  const dtConfig = React.useMemo(() => {
    return {
      columns,
      columnDefs: [
        {
          targets     : reqIdColIdx,
          createdCell : (td, cellData) => {
            const href = `/burn-requests/${cellData}`
            return buildCellLink(td, href, cellData)
          },
        },
        {
          targets     : [ permNumColIdx, ],
          createdCell : (td, cellData, rowData) => {
            const href = `/permits/${rowData.BurnPermitId}`
            return buildCellLink(td, href, cellData)
          },
        },
        {
          targets     : [ postBurnColIdx, ],
          createdCell : (td, hasPostBurn, rowData) => {
            const href = `/permits/${rowData.BurnPermitId}/postburn?burnRequestId=${rowData.BurnRequestId}`
            let cellData = <>Enter <DangerIcon /></>
            if (hasPostBurn) {
              cellData = <>View <SuccessIcon /></>
            }
            return buildCellLink(td, href, cellData)
          },
        },
      ],
      data           : requests,
      scrollY        : '65vh',
      scrollCollapse : true,
      order          : [],
      rowCallback    : (row) => row.classList.add('no-click'),
      buttons        : [
        'colvis',
        'excel',
        'print',
        {
          extend   : 'csvHtml5',
          filename : getFileName,
        },
        'pdf',
      ],
    }
  }, [ buildCellLink, columns, permNumColIdx, postBurnColIdx, reqIdColIdx, requests, getFileName, ])

  const ToggleMap = React.useCallback(() => reduxDispatch(BurnRequestListActions.toggleMap()), [ reduxDispatch, ])

  const mapBtnText = useMemo(() => {
    let btnText = 'Show Map'
    if (showMap) {
      btnText = 'Hide Map'
    }
    return btnText
  }, [ showMap, ])

  const mapData = useMemo(() => {
    let data = []
    if (!isEmpty(BurnRequestsMapLayer)) {
      data = [ BurnRequestsMapLayer, ]
    }
    return data
  }, [ BurnRequestsMapLayer, ])

  return <>
    <Card className={'w-100'}>
      { showCardHeader && <CardHeader className={'d-flex justify-content-between'}>
        <div>
          <Link to={'/burn-requests/new'}>New Request</Link>
        </div>
        <div>
          <Button color={'light'} size={'sm'} onClick={ToggleMap}>{mapBtnText}</Button>
        </div>
      </CardHeader>
      }
      <CardBody className={'pt-0 px-2'}>
        {aboveTableContent}
        <Row className={mapClass}>
          <Col style={mapColStyle}>
            <ESRIMap
              config={layerConfig}
              mapData={mapData}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SimpleDataTable config={dtConfig} elementId={'request-search-table'} />
          </Col>
        </Row>
        {belowTableContent}
      </CardBody>
    </Card>
  </>
}

BurnRequestTable.propTypes = {
  showLandownerAgent : bool,
  showMap            : bool,
  showCardHeader     : bool,
  requests           : array,
  aboveTableContent  : node,
  belowTableContent  : node,
  isAuthenticated    : bool,
  showPermitAction   : bool,
  layerConfig        : string,
}

export default BurnRequestTable