// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Navigate, } from 'react-router-dom'
import { isEmpty, } from 'lodash'
import { object, func, string, bool, } from 'prop-types'
import { Container, Row, Col, Card, CardHeader, CardBody, } from 'reactstrap'

// Actions
import ApiActions from '../redux/ApiRedux'
import AppActions from '../redux/AppRedux'
import BurnPermitOrderActions from '../redux/BurnPermitOrderRedux'

// Components
import withRouter from '../components/withRouter'

// Selectors
import { orderByIdSelector, } from '../selectors/orderSelectors'
import { userCanRecordCashCheckPayment, } from '../selectors/burnPermitPermissionSelectors'

// Utilities
import { queryParamsToObject, } from '../utilities'

// eslint-disable-next-line no-undef
const { REACT_APP_PAYMENT_ROOT_URL, } = process.env


export class BurnPermitOrderPayContainer extends React.Component {
  static propTypes = {
    // from withRouter HOC
    params   : object,
    navigate : func,
    location : object,

    Failure                  : func,
    SetPageTitle             : func,
    UpdateBurnPermitOrder    : func,
    GetBurnPermitOrderDetail : func,
    order                    : object,
    isDnr                    : bool,
    isVerifiedAgent          : bool,
    isStateGovCust           : bool,
    isGovAgent               : bool,
    paysByVoucher            : bool,
    isPrivate                : bool,
    isAgent                  : bool,
    redirectTo               : string,
    canRecordCashCheck       : bool,
  }

  state = {
    error         : '',
    redirectTo    : '',
    updatingOrder : false,
    redirecting   : false,
    processing    : false,
  }

  orderIdRegex = /\/*\/(\d+)\/*/g

  componentDidMount () {
    this.props.SetPageTitle('Pay Order')
    this.handleOrder()
  }

  componentDidUpdate (prevProps) {
    // If the order did not have a hash, but the app state has since been updated to
    // set the hash on the order, that is an indicator the API updated the order and
    // returned a successful response
    const { paramsObj, } = this.state
    if (!isEmpty(paramsObj) && this.props.order.PaymentHash === paramsObj.hash) {
      // Redirect the user to the payment detail
      this.props.navigate(`/permits/orders/${this.props.order.BurnPermitOrderId}`)
    }
    if (prevProps.redirectTo && !this.props.redirectTo) {
      this.navToPaymentSite()
    }
    if (!prevProps.order && !!this.props.order) {
      this.handleOrder()
    }
  }

  handleOrder = () => {
    const {
      order,
      canRecordCashCheck,
      GetBurnPermitOrderDetail,
      isVerifiedAgent,
      location,
      isStateGovCust,
      isPrivate,
      isAgent,
      isGovAgent,
      paysByVoucher,
    } = this.props
    
    const isRedirectBackFromPaymentSite = location.pathname.endsWith('?')
    const hasPaymentState = (location.state && location.state.onlinePayment === true)
    const isVerifiedGovCust = (isStateGovCust && isVerifiedAgent)
    const onlyPaysOnline = (isPrivate || (isAgent && isVerifiedAgent) || (isGovAgent && !paysByVoucher))
    const isPaymentMatch = location.pathname.endsWith('/pay')

    // If the user has reached this point, they somehow lost the order object out of their local state
    // but the URL should have an orderId in it, otherwise react router would not have matched to this
    // container to mount it
    if (!order) {
      const regMatch = location.pathname.match(this.orderIdRegex)
      if (regMatch.length) {
        const orderId = regMatch[0].replaceAll('/', '')
        GetBurnPermitOrderDetail(orderId)
        this.setState({ processing: true, })
        return
      }
      else {
        throw new Error('Could not locate or determine the Burn Permit Order or Order ID. Please contact support.')
      }
    }
    else if (order && !canRecordCashCheck) {
      const paramsObj = queryParamsToObject()
      // This should be a payment that's come back from payment processing if there are query arguments
      if (Object.keys(paramsObj).length > 0) {
        this.setState({ processing: false, })
        this.submitOrder(paramsObj)
        return
      }
      // DNR users always go to the order detail page
      // Redirects from the payment site (cancelled payments) go back to the detail page
      // Gov Customers go to the order detail page unless specified with state params
      else if ((this.props.isDnr) || (isVerifiedGovCust && !hasPaymentState) || isRedirectBackFromPaymentSite) {
        let route = `/permits/orders/${order.BurnPermitOrderId}`
        if (this.props.order.PaymentHash) {
          route += '?showNextSteps=true'
        }
        this.props.navigate(route)
      }
      // If the URL is specifically for Payment and this is either 
      // a user type that only pays online or is a gov customer 
      // with a state request set up, redirect to the portal
      else if ((isPaymentMatch) && ((isVerifiedGovCust && hasPaymentState) || onlyPaysOnline)) {
        this.navToPaymentSite()
      }
    }
    else {
      let route = `/permits/orders/${order.BurnPermitOrderId}`
      if (this.props.order.PaymentHash) {
        route += '?showNextSteps=true'
      }
      this.setState({ redirectTo: route, })
    }
  }

  submitOrder = paramsObj => {
    const { order, UpdateBurnPermitOrder, } = this.props
    const orderInfo = {
      BurnPermitOrderId        : order.BurnPermitOrderId,
      BurnPermitIds            : order.BurnPermitOrderXref.map(x => x.BurnPermitId),
      EPayResultMessage        : paramsObj.m,
      EPayReturnCode           : paramsObj.c,
      ConfirmationNumber       : paramsObj.o,
      TotalAmountPaid          : paramsObj.t,
      SettlementSubmissionDate : paramsObj.d,
      AuthorizationCode        : paramsObj.z,
      CardType                 : paramsObj.ct,
      PaymentHash              : paramsObj.hash,
    }
    // If the payment is not successful, show the message from PayPoint
    if (orderInfo.EPayReturnCode !== '1' && orderInfo.EPayReturnCode !== '2') {
      this.setState({ error: orderInfo.EPayResultMessage.replaceAll('"', ''), })
      return
    }
    UpdateBurnPermitOrder(orderInfo)

    this.setState({ updatingOrder: true, paramsObj, })
  }

  navToPaymentSite = () => {
    this.setState({ redirecting: true, })
    const { order, } = this.props
    if (!order.BurnPermitOrderAmount) {
      this.props.Failure('No order amount was found. Please refresh then try again.')
      this.props.navigate(-1)
      return
    }
    const queryParams = {
      returnurl : window.location.href,
      custom    : `$${order.BurnPermitOrderAmount},${order.BurnPermitOrderId}`,
      amount    : order.BurnPermitOrderAmount,
    }

    let queryString = Object.keys(queryParams).map(key => `${key}=${encodeURIComponent(queryParams[key])}`).join('&')
    window.location.href = `${REACT_APP_PAYMENT_ROOT_URL}?${queryString}`
  }

  render () {
    const {
      redirectTo,
      redirecting,
      processing,
      paramsObj,
      error,
    } = this.state

    let header = '', body = ''
    if (redirecting) {
      header = 'Redirecting to Payment Site'
      body = 'Please wait while we redirect you to the Payment Site. This may take a few seconds.'
    }
    else if (!isEmpty(paramsObj) || processing) {
      header = 'Confirming Payment'
      body = 'Please wait while we confirm your payment. You will be redirected once complete.'
    }
    else if (error) {
      header = 'Error'
      body = error || 'An unknown error occurred. Please contact support.'
    }
    if (redirectTo) {
      return <Navigate to={redirectTo} />
    }
    
    return (
      <Container className={'my-4'}>
        <Row className={'mb-3'}>
          <Col sm={{ size: 6, offset: 3, }}>
            <Card>
              <CardHeader tag={'h3'}>{header}</CardHeader>
              <CardBody>
                <p>{body}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

function mapStateToProps (state, props) {
  return {
    order              : orderByIdSelector(state, props.params.BurnPermitOrderId),
    canRecordCashCheck : userCanRecordCashCheckPayment(state),
    redirectTo         : state.app.redirectToRoute,
  }
}

const mapDispatchToProps = {
  SetPageTitle             : AppActions.setPageTitle,
  Failure                  : ApiActions.failure,
  GetBurnPermitOrderDetail : BurnPermitOrderActions.getBurnPermitOrderDetail,
  UpdateBurnPermitOrder    : BurnPermitOrderActions.updateBurnPermitOrder,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnPermitOrderPayContainer))