// Libraries
import React, { useCallback, useMemo, useState, } from 'react'
// import { FormFeedback, } from 'reactstrap'
import { string, array, func, object, bool, number, } from 'prop-types'
import ReactSelect from 'react-select'
import CreateableSelect from 'react-select/creatable'
import { useFormikContext, } from 'formik'
 
const AutoComplete = (props) => {
  const {
    autoFocus,
    error,
    fieldName,
    items,
    minCharactersToEnter,
    defaultMenuIsOpen,
    onChange,
    readOnly,
    placeholder,
    createable,
    searchValue,
  } = props

  let { form, value, } = props

  const [ showOptions, ] = useState(true)
  const [ createdOptions, setCreatedOptions, ] = useState([])

  const formik = useFormikContext()
  if (!form && formik) {
    form = formik
  }

  const options = useMemo(() => {
    if (!showOptions) {
      return []
    }
    return [
      ...createdOptions,
      ...items.map(i => ({ value: i.Value, label: i.Text, })),
    ]
  }, [ showOptions, createdOptions, items, ])

  if (!value && form && fieldName) {
    const fieldNameProps = fieldName.split('.')
    let currVal = form.values
    fieldNameProps.forEach(key => {
      currVal = currVal[key]
    })
    value = options.find(o => o.value === currVal)
    if (!value) {
      setCreatedOptions([ ...createdOptions, { value: currVal, label: currVal, }, ])
    }
  }
  else if (typeof value === 'string') {
    value = options.find(o => o.label === value)
  }

  const minChars = useMemo(() => {
    if (!`${minCharactersToEnter}`.length) {
      return 1
    }
    return minCharactersToEnter
  }, [ minCharactersToEnter, ])

  const _onChange = useCallback((evt, action) => {
    let label = '', value = ''
    if (evt) {
      ({ label, value, } = evt)
    }
    if (typeof onChange === 'function') {
      onChange({ label, value, }, action)
    }
    if (form && typeof form.setFieldValue === 'function') {
      form.setFieldValue(fieldName, value)
    }
  }, [ fieldName, form, onChange, ])

  const shouldItemsRender = useCallback((opt, input) => {
    if ((!input || !input.length) && minChars > 0) {
      return false
    }
    if (input.length < minChars) {
      return false
    }
    return opt.data.__isNew__ || (opt.label && opt.label.toLowerCase().includes(input.toLowerCase()))
  }, [ minChars, ])

  const components = useMemo(() => ({
    DropdownIndicator  : () => null,
    IndicatorSeparator : () => null,
  }), [])

  const customStyles = useMemo(() => ({
    option  : defaultStyles => ({ ...defaultStyles, }),
    control : defaultStyles => ({
      ...defaultStyles,
      borderTop    : 'none',
      borderRight  : 'none',
      borderLeft   : 'none',
      borderRadius : 'none',
      border       : '0rem solid transparent',
      paddingTop   : '0.25em',
    }),
    singleValue: defaultStyles => ({ ...defaultStyles, }),
  }), [])

  const noOptionsMessage = useCallback(({ inputValue, }) => {
    if (minCharactersToEnter === 0) {
      return 'No matches'
    }
    const remainingValLen = minCharactersToEnter - inputValue.length
    return inputValue && remainingValLen <= 0
      ? 'No matches'
      : `Enter ${remainingValLen} more character${remainingValLen > 1 ? 's' : ''} to see matches`
  }, [ minCharactersToEnter, ])

  const className = useMemo(() => (error ? 'is-invalid' : ''), [ error, ])
  const compProps = {
    autoFocus         : autoFocus,
    className         : className,
    classNamePrefix   : 'react-select',
    defaultValue      : value,
    defaultInputValue : searchValue || '',
    defaultMenuIsOpen : defaultMenuIsOpen,
    isClearable       : true,
    isSearchable      : true,
    name              : fieldName,
    options           : options,
    onChange          : _onChange,
    filterOption      : shouldItemsRender,
    components        : components,
    styles            : customStyles,
    placeholder       : placeholder,
    noOptionsMessage  : noOptionsMessage,
    readOnly          : readOnly,
    value             : value || '',
  }

  if (createable) {
    return <CreateableSelect {...compProps} />
  }

  return <ReactSelect {...compProps} />
}

AutoComplete.propTypes = {
  items                : array.isRequired,
  fieldName            : string,
  value                : string,
  searchValue          : string,
  onChange             : func,
  form                 : object,
  error                : string,
  invalid              : bool,
  autoFocus            : bool,
  shouldFocus          : bool,
  readOnly             : bool,
  defaultMenuIsOpen    : bool,
  minCharactersToEnter : number,
  placeholder          : string,
  createable           : bool,
}

AutoComplete.defaultProps = {
  fieldName            : '',
  value                : '',
  autoFocus            : false,
  shouldFocus          : false,
  readOnly             : false,
  defaultMenuIsOpen    : false,
  minCharactersToEnter : 1,
  invalid              : false,
  placeholder          : '',
  createable           : false,
}

export default React.memo(AutoComplete)
